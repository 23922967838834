import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="athletes-attendance"
export default class extends Controller {
  static targets = ["select", "label", "options"];
  static values = { url: String };

  async change(e) {
    this.labelTarget.innerText = "Loading...";
    this.optionsTarget.innerHTML = "";

    try {
      const res = await fetch(`${this.urlValue}data=${e.target.value}`);
      const data = await res.json();

      this.labelTarget.innerText = `${data.year} ${data.month_name} -> ${data.count}`;
      this.optionsTarget.innerHTML = data.data.map((item) => `<li>${item}</li>`).join("");
    } catch (err) {
      this.labelTarget.innerText = "Error...";
    }
  }
}
