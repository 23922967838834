/* eslint no-console:0 */
import "bootstrap/dist/js/bootstrap";
import "bootstrap-select/js/bootstrap-select";
import "../controllers";

import { Pagy } from "../src/javascripts/pagy.js.erb";
global.Pagy = Pagy;

require("jquery");
window.hoverintent = require("hoverintent/dist/hoverintent.min.js");

require("@rails/ujs").start();
require("turbolinks").start();

import splitbee from "@splitbee/web";

const dev = process.env.NODE_ENV === "development";
if (!dev) {
  splitbee.init();
}

window.addEventListener("turbolinks:load", () => {
  Pagy.init();
  const data = document.body.dataset;
  if (data.user) {
    try {
      const userData = JSON.parse(data.user);
      splitbee.user.set({
        id: userData.id,
        email: userData.email,
        name: userData.name,
        role: userData.role,
        namespace: userData.role === "admin" ? undefined : userData.namespace,
      });
    } catch (e) {
      console.log(e);
    }
  }

  $.fn.selectpicker.Constructor.BootstrapVersion = "4";
  $(window).trigger("load.bs.select.data-api");

  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    $.fn.selectpicker.Constructor.DEFAULTS.mobile = true;
  }
});
